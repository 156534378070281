import React from "react"
import { graphql } from "gatsby"
import { Article } from "../Article"
import {
  sectionClass
} from "./StoryContent.module.scss"

export const fragment = graphql`
  fragment storyContentFragment on WpStory_Storieslayouts_StoryObject_StoryContent {
    storyTitle
    blurb
    mainImage {
      altText
      sourceUrl
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            width: 1032
            placeholder: BLURRED
          )
        }
      }
    }
    storyBody {
      ... on WpStory_Storieslayouts_StoryObject_StoryContent_StoryBody_SectionHeadingContent {
        sectionHeading
        headingType
      }
      ... on WpStory_Storieslayouts_StoryObject_StoryContent_StoryBody_TextContent {
        text
      }
      ... on WpStory_Storieslayouts_StoryObject_StoryContent_StoryBody_ImageContent {
        image {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 680
                placeholder: BLURRED
              )
            }
          }
        }
      }
      ... on WpStory_Storieslayouts_StoryObject_StoryContent_StoryBody_DoubleImageBlockContent {
        doubleImageBlock {
          leftImage {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 420
                  placeholder: BLURRED
                )
              }
            }
          }
          rightImage {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 420
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
      ... on WpStory_Storieslayouts_StoryObject_StoryContent_StoryBody_QuoteContent {
        quote
      }
      ... on WpStory_Storieslayouts_StoryObject_StoryContent_StoryBody_VideoContent {
        video
      }
    }
  }
`

export const StoryContent = ({
  location,
  history,
  pageContext,
  date,
  author,
  blurb,
  storyTitle,
  mainImage,
  storyBody,
  uri
}) => {

  return (
    <section className={sectionClass}>
       <Article
        location={location}
        history={history}
        pageContext={pageContext}
        date={date}
        author={author}
        blurb={blurb}
        postTitle={storyTitle}
        headingType="h1"
        mainImage={mainImage}
        postBody={storyBody}
        uri={uri}
      />
    </section>
  )
}
