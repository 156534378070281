import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"

import { StoryContent } from "../components/AcfStoriesBlocks"

export const storyQuery = graphql`
  query GET_STORY($id: String!) {
    wpStory(id: { eq: $id }) {
      title
      uri
      date(formatString: "DD MMM YYYY", locale: "en-GB")
      author {
        node {
          name
        }
      }
      storiesLayouts {
        storyObject {
          __typename
          ...storyContentFragment
        }
      }
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`

const Story = ({ location, history, data, pageContext }) => {
  const {
    title,
    uri,
    date,
    author: {
      node: { name }
    },
    storiesLayouts: { storyObject },
    seo
  } = data.wpStory
  return (
    <Layout seo={seo} location={location}>
      {storyObject.length > 0 &&
        storyObject.map((block, i) => {
          switch (block.__typename) {
            case "WpStory_Storieslayouts_StoryObject_StoryContent":
              return (
                <StoryContent
                  location={location}
                  history={history}
                  pageContext={pageContext}
                  date={date}
                  author={name}
                  key={`StoryContent${i}`}
                  uri={uri}
                  {...block}
                />
              )
            default:
              console.log("This is the End!", block.__typename)
              return "" // this should not happen
          }
        })}
    </Layout>
  )
}

export default Story
